// External
import React, {
	useContext,
	useEffect,
	useState,
	Suspense,
	lazy,
	useRef,
} from 'react';

// Internal
import ProductInfoCollection from './ProductInfoCollection';
import ProductInfoTitle from './ProductInfoTitle';
import ProductInfoPrice from './ProductInfoPrice';
import ProductInfoDescription from './ProductInfoDescription';
import ProductAdvantages from '../ProductAdvantages';
import ProductVariantSelector from './ProductVariantSelector';
import { StoreContext } from '../../../../context/store-context';
import { useWindowWidth } from '../../../../utils/hooks';
import {
	getDiscountPrice,
	getMetafield,
	getSellingPlanGroupDefaultValue,
	getSellingPlanGroupOptions,
	isAvailable,
} from '../../../../utils/helpers';
import Button from '../../../../components/Button';
import Loader from '../../../../components/Loader';

import './ProductInfo.scss';

const ProductRecommended = lazy(() => import('../ProductRecommended'));
const ShopTheLook = lazy(() => import('../ShopTheLook'));
const ProductTooltip = lazy(() => import('../ProductTooltip'));
const ProductBundlesSection = lazy(() => import('../ProductBundlesSection'));
const ProductBundlePiece = lazy(() =>
	import('../ProductBundlePiece/ProductBundlePiece')
);
const UpsellPDP = lazy(() => import('../../../../components/UpsellPDP'));

const ProductInfo = ({
	product,
	cart,
	collections,
	shopLookProductsHandles,
	recommendedProduct,
	recommendedProductHandle,
	isRecommendedSelected,
	windowWidth,
	rating,
	sellingPlanGroup,
	miracleSystem,
	activeVariant,
	setActiveVariant,
	scrollToReviews,
	productsList,
	allBaseColours,
	withShadeShot,
	shots,
	shadeShotsProducts,
	isDiscountGroup,
	MiracleSystemSellingPlanGroup,
}) => {
	const {
		subscriptionsEnabled,
		setModal,
		showModal,
		setSlideProperties,
		setIsProductInfoButtonVisible,
	} = useContext(StoreContext);
	const [selectedProduct, setSelectedProduct] = useState({
		product,
		oldPrice: null,
		price: null,
		units: 1,
	});
	const isSubscribeOnPDP = getMetafield(product, 'subscription_on_pdp');
	const [groupFound, setGroupFound] = useState(
		Object.keys(sellingPlanGroup).length > 0
	);
	const [canSubscribe, setCanSubscribe] = useState(
		groupFound && subscriptionsEnabled && isSubscribeOnPDP
	);
	const [options, setOptions] = useState(
		canSubscribe
			? getSellingPlanGroupOptions(sellingPlanGroup.sellingPlans.edges)
			: []
	);
	const isHintActive = JSON.parse(
		getMetafield(product, 'is_active_send_hint')
	);

	const [activeRadio, setActiveRadio] = useState(
		canSubscribe ? 'auto-delivery' : 'One-Time Purchase'
	);
	const [selectedValue, setSelectedValue] = useState(
		canSubscribe ? getSellingPlanGroupDefaultValue(options) : null
	);
	const [discountPrice, setDiscountPrice] = useState(
		product.variants[0].price
	);
	const [discountPercentageWithSub, setDiscountPercentageWithSub] =
		useState(0);
	const width = useWindowWidth();
	const isMiracle = getMetafield(product, 'is_miracle_system');
	const productHasUpsellActivated = getMetafield(product, 'include_upsell');
	const numberOfSelectionLeves = getMetafield(product, 'number_of_selection_levels') ?? 0;
	const isGiftCard =
		product.handle.includes('gift') &&
		!product.handle.includes('comb') &&
		!canSubscribe;
	const optionsObjects = options.map((opt, i) => ({
		value: i,
		label: `Every ${opt} ${opt === '4 Weeks' ? '(most common)' : ''}`,
	}));

	const isVariant = JSON.parse(getMetafield(product, 'is_variant'));
	const isPermHairColourWithVariants = JSON.parse(
		getMetafield(product, 'is_perm_hair_colour_with_variants')
	);

	const isBundlePieceBrief = JSON.parse(
		getMetafield(product, 'is_bundle_piece_brief')
	);

	const [selectedVariant, setSelectedVariant] = useState(undefined);

	const openModalHint = () => {
		setModal(product, 'hint-modal', '', null);
		showModal(true);
	};

	const isMobile = width < 550;

	const [variantID, setVariantID] = useState(null);
	const [availableForSale, setAvailableForSale] = useState(true);

	useEffect(() => {
		const handleVariantID = () => {
			if (activeVariant && activeVariant.storefrontId)
				setVariantID(activeVariant.storefrontId);
		};
		handleVariantID();
	}, [selectedVariant]);

	useEffect(() => {
		const handleFirstVariant = () => {
			if (!product || !isVariant) return null;
			setSelectedVariant(product.variants[0]);
			setActiveVariant(product.variants[0]);

			const variantSelectImage = product?.variants[0]?.metafields?.find(
				(metafield) => metafield.key === 'selected_variant'
			)?.value;

			const data = variantSelectImage
				? {
						...product?.variants[0],
						variantSelectImage,
				  }
				: product?.variants[0];

			setSlideProperties(data);
		};
		handleFirstVariant();
	}, [product, isVariant]);

	useEffect(() => {
		const handleSelectedVariant = () => {
			if (!selectedVariant || !isVariant) return;
			const targetDiv = document.querySelectorAll(
				'.target-variant__product'
			);
			for (const variant of targetDiv) {
				if (variant.getAttribute('data-id') === selectedVariant.id) {
					variant.style.border = '3px solid pink';
				} else {
					variant.style.border = '3px solid transparent';
				}
			}
		};
		handleSelectedVariant();
	}, [selectedVariant, isVariant]);

	useEffect(() => {
		if (canSubscribe) {
			const plan = sellingPlanGroup.sellingPlans.edges[selectedValue];
			const productPrice = parseInt(product?.variants[0].price);
			const discountPercentage =
				plan?.node.pricingPolicies[0].adjustmentValue.percentage;
			const discount = getDiscountPrice(productPrice, discountPercentage);
			setDiscountPercentageWithSub(discountPercentage);
			setDiscountPrice(discount);
		}
	}, [canSubscribe]);

	useEffect(() => {
		setAvailableForSale(
			isVariant && selectedVariant
				? selectedVariant.availableForSale
				: isAvailable(selectedProduct.product)
		);
	}, [selectedVariant]);

	const buttonRef = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				setIsProductInfoButtonVisible(entry.isIntersecting);
			},
			{
				threshold: 0.2,
				rootMargin: "-80px 0px 0px 0px"
			}
		);

		if (buttonRef.current) {
			observer.observe(buttonRef.current);
		}

		return () => {
			if (buttonRef.current) {
				observer.unobserve(buttonRef.current);
			}
		};
	}, []);

	return (
		<div className='pdp-info'>
			<ProductInfoCollection
				product={product}
				collections={collections}
			/>
			{!isGiftCard && (
				/* eslint-disable */
				<>
					<ProductInfoTitle
						product={product}
						productsList={productsList}
						allBaseColours={allBaseColours}
						withShadeShot={withShadeShot}
						shots={shots}
						shadeShotsProducts={shadeShotsProducts}
						sellingPlanGroup={sellingPlanGroup}
						miracleSystem={miracleSystem}
					/>
					<ProductInfoPrice
						activeRadio={activeRadio}
						isDiscountGroup={isDiscountGroup}
						discountPrice={discountPrice}
						canSubscribe={canSubscribe}
						cart={cart}
						product={product}
						rating={rating}
						isRecommendedSelected={isRecommendedSelected}
						recommendedProduct={recommendedProduct}
						scrollToReviews={scrollToReviews}
						discountPercentageWithSub={discountPercentageWithSub}
						selectedVariant={selectedVariant}
						selectedProduct={selectedProduct}
					/>
					<ProductInfoDescription product={product} />
					{isMobile && isBundlePieceBrief && (
						<Suspense fallback={<Loader />}>
							<ProductBundlePiece
								product={product}
								setSelectedProduct={setSelectedProduct}
							/>
						</Suspense>
					)}

					<ProductAdvantages product={product} />
					{!isMobile && isBundlePieceBrief && (
						<Suspense fallback={<Loader />}>
							<ProductBundlePiece
								product={product}
								setSelectedProduct={setSelectedProduct}
							/>
						</Suspense>
					)}
					{product &&
						isVariant &&
						selectedVariant &&
						product?.totalInventory > 0 && (
							<ProductVariantSelector
								product={product}
								selectedVariant={selectedVariant}
								setSelectedVariant={setSelectedVariant}
								setActiveVariant={setActiveVariant}
								isPermamentHairColour={
									isPermHairColourWithVariants
								}
							/>
						)}
					{isMobile && !isMiracle && !canSubscribe && numberOfSelectionLeves < 1 && (
						<div className='button-action-pdp' ref={buttonRef}>
							<Button
								buttonText='Add to bag'
								isPinkBasket
								product={selectedProduct.product}
								availableForSale={availableForSale}
								isVariant={isVariant}
								variantId={variantID}
							/>

							{isHintActive && (
								<Button
									isAction
									isPink
									isNotProduct
									buttonText='Send a hint'
									icon='gift.svg'
									handleClick={openModalHint}
								/>
							)}
						</div>
					)}
				</>
			)}

			{recommendedProductHandle && windowWidth <= 991 && (
				<Suspense fallback={<Loader />}>
					<ProductRecommended {...props} />
				</Suspense>
			)}
			{recommendedProductHandle && windowWidth <= 991 && (
				<Suspense fallback={<Loader />}>
					<ProductRecommended
						{...{
							product,
							cart,
							collections,
							shopLookProductsHandles,
							recommendedProduct,
							recommendedProductHandle,
							isRecommendedSelected,
							windowWidth,
							rating,
						}}
					/>
				</Suspense>
			)}
			{shopLookProductsHandles && (
				<Suspense fallback={<Loader />}>
					<ShopTheLook
						shopLookProductsHandles={shopLookProductsHandles.split(
							'|'
						)}
					/>
				</Suspense>
			)}
			<Suspense fallback={<Loader />}>
				<ProductBundlesSection
					{...{
						product: selectedProduct.product,
						cart,
						collections,
						shopLookProductsHandles,
						recommendedProduct,
						recommendedProductHandle,
						isRecommendedSelected,
						windowWidth,
						rating,
						canSubscribe,
						subscriptionsEnabled,
						discountPrice,
						activeRadio,
						setActiveRadio,
						variantSelected: selectedVariant,
						selectedValue,
						setSelectedValue,
						sellingPlanGroup,
						optionsObjects,
						activeVariant,
						setActiveVariant,
						isGiftCard,
						discountPercentageWithSub,
						selectedVariant,
						isHintActive,
						openModalHint,
						isVariant,
					}}
					options={options}
				/>
			</Suspense>
			{!canSubscribe ? null : (
				<Suspense fallback={<Loader />}>
					<ProductTooltip product={product} isMiracle={isMiracle} />
				</Suspense>
			)}
			{productHasUpsellActivated && (
				<Suspense fallback={<Loader />}>
					<UpsellPDP
						sellingPlanGroup={MiracleSystemSellingPlanGroup}
						product={product}
					/>
				</Suspense>
			)}
		</div>
	);
};

export default ProductInfo;
