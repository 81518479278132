// External
import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { StaticImage } from 'gatsby-plugin-image';

// Internal

import { useWindowWidth } from '../../utils/hooks';
import getVariantPrice from '../../helpers/productsPricing';

import chevron from '../../../static/images/chevron.png';
import Loader from '../Loader';

import './DeliveryRadioV3.scss';
import { getMetafield, getMetafieldParse } from '../../utils/helpers';

const MiracleSelect = React.lazy(() => import('../MiracleSelect'));

const DeliveryRadioV3 = ({
	activeRadio,
	setActiveRadio,
	radios,
	center,
	classname,
	discountPercentageWithSub = 15,
	product,
	levelValidator,
	setErrorSelectLevel,
	optionsObjects,
	selectedValue,
	setSelectedValue,
	usesShadeShotSelection,
}) => {
	const products = [
		{
			price: Number(getMetafield(product, 'custom_price_product_1')) ?? 0,
			name: getMetafield(product, 'custom_product_name_1'),
			image: getMetafieldParse(product, 'custom_image_product_1'),
		},
		{
			price: Number(getMetafield(product, 'custom_price_product_2')) ?? 0,
			name: getMetafield(product, 'custom_product_name_2'),
			image: getMetafieldParse(product, 'custom_image_product_2'),
		},
		{
			price: Number(getMetafield(product, 'custom_price_product_3')) ?? 0,
			name: getMetafield(product, 'custom_product_name_3'),
			image: getMetafieldParse(product, 'custom_image_product_3'),
		},
	];
	const worthTotal =
		products[0].price + products[1].price + products[2].price;

	const radioDeliveryOptions = [
		{
			title: `Subscribe & Save`,
			subtitle: `Free gifts with your first order worth £${worthTotal}:`,
			price: getVariantPrice(product, true, discountPercentageWithSub),
			activeSelect: true,
			val: 'auto-delivery',
		},
		{
			title: 'One-time purchase',
			subtitle: `${product.title} only, no free gifts included`,
			price: getVariantPrice(product),
			val: 'One-Time Purchase',
		},
	];
	const radiosList = radios || radioDeliveryOptions;

	const [isOpen, setIsOpen] = useState(false);
	const [rotateChevron, setRotateChevron] = useState(false);

	const handleSelect = () => {
		setIsOpen(!isOpen);
	};
	const usesTwoLevelsValidation = usesShadeShotSelection
		? typeof levelValidator === 'object' &&
		  levelValidator?.isValidOne &&
		  levelValidator?.isValidTwo
		: typeof levelValidator === 'object' && levelValidator?.isValidOne;
	const isValid = usesTwoLevelsValidation;

	const getActive = (value) => {
		if (typeof levelValidator !== 'object') return value;
		if (isValid) return value;
	};

	const isAutoDelivery = activeRadio === 'auto-delivery';

	const width = useWindowWidth();

	const validationErrorRadioDelivery = (val) => {
		if (typeof levelValidator === 'object') {
			if (!levelValidator?.isValidOne) {
				setErrorSelectLevel &&
					setErrorSelectLevel({
						errorLevelOne: 'Please choose colour before',
						errorLevelTwo: '',
					});
			} else if (!levelValidator?.isValidTwo) {
				setErrorSelectLevel &&
					setErrorSelectLevel({
						errorLevelOne: '',
						errorLevelTwo: 'Please choose tone before',
					});
			}
		}

		if (typeof levelValidator !== 'object') {
			setActiveRadio(val);
		} else if (isValid) {
			setActiveRadio(val);
		}
	};

	useEffect(() => {
		const setStylePositions = () => {
			const radioPosition = isAutoDelivery ? '3' : '2';
			const lastRadio = document.querySelector(
				`.delivery-radios-v3__item:nth-child(${radioPosition})`
			);
			const unsubscribe = document.querySelector(
				'.delivery-radios-v3__unsubscribe'
			);
			if (!lastRadio || !levelValidator) return;
			if (width > 550) {
				if (unsubscribe) unsubscribe.style.bottom = '-25px';
				lastRadio.style.marginTop = '0px';
				return;
			}
			if (
				levelValidator.isValidOne &&
				levelValidator.isValidTwo &&
				isAutoDelivery
			) {
				lastRadio.style.marginTop = '100px';
				if (unsubscribe) unsubscribe.style.bottom = 'calc(50% + 32px)';
			} else {
				lastRadio.style.marginTop = '25px';
				if (unsubscribe) unsubscribe.style.bottom = 'calc(50% - 10px)';
			}
		};
		setStylePositions();
	}, [levelValidator, activeRadio]);

	return (
		<div
			className={`delivery-radios-v3 ${
				center && 'delivery-radios-v3--center'
			} ${classname && classname}`}
			id='delivery-radios-version-3'
		>
			{isAutoDelivery && (
				<span className='delivery-radios-v3__deliver'>
					<b>Subscribe & Save</b>
				</span>
			)}

			<div className='delivery-radios-v3__container'>
				{radiosList.map((radio, index) => {
					const val = radio.val.toLowerCase().replace(' ', '-');
					return (
						<div
							key={radio.val}
							className='delivery-radios-v3__container-box'
							style={{
								border:
									activeRadio === getActive(val)
										? `1px solid #D56E8F`
										: '1px solid #CDCDCD',
								opacity:
									activeRadio === getActive(val)
										? '1'
										: '0.5',
							}}
							onClick={() => validationErrorRadioDelivery(val)}
						>
							{index === 0 && (
								<p className='delivery-radios-v3__container-box-first-discount'>
									{discountPercentageWithSub}% off
								</p>
							)}
							<div className='delivery-radios-v3__container-box-first'>
								<label>
									<input
										type='radio'
										name='purchase_type'
										value='auto-delivery'
										checked={activeRadio === getActive(val)}
									/>
									<span
										className={`
									${
										activeRadio === getActive(val)
											? 'delivery-radios-v3__container-box-selected'
											: 'delivery-radios-v3__container-box-no-selected'
									}
								`}
									/>
								</label>
								<div className='delivery-radios-v3__container-box-first-box-text'>
									<div className='delivery-radios-v3__container-box-first-text'>
										<p className='bold'>{radio.title}</p>
										{radio.price && (
											<p className='bold delivery-radios-v3__container-box-first-box-text__price'>
												{index === 0 && (
													<span>
														{getVariantPrice(
															product
														)}
													</span>
												)}

												{radio.price}
											</p>
										)}
									</div>
									<div className='delivery-radios-v3__container-box-first-box-text__subtitle'>
										{radio.subtitle && (
											<p>{radio.subtitle}</p>
										)}
									</div>
								</div>
							</div>
							{index === 0 && (
								<div
									className='delivery-radios-v3__container-box__products'
									style={{
										gap: products[2]?.name ? '5px' : '30px',
									}}
								>
									{products?.map((product, index) => (
										<>
											{product.name && (
												<>
													{index > 0 && (
														<p className='delivery-radios-v3__container-box__products-separator'>
															+
														</p>
													)}
													<div className='delivery-radios-v3__container-box__products-item'>
														<div className='delivery-radios-v3__container-box__products-item__image'>
															<p>
																£{product.price}
															</p>
															<LazyLoadImage
																src={
																	product
																		.image[0]
																		?.src
																}
																alt={`${product.name} image`}
															/>
														</div>

														<p
															style={{
																height: products[2]
																	?.name
																	? '50px'
																	: '30px',
															}}
														>
															FREE {product.name}
														</p>
													</div>
												</>
											)}
										</>
									))}
								</div>
							)}

							{radio.activeSelect && (
								<>
									<h5
										style={{
											marginBlockStart: products[2]?.name
												? '0px'
												: '10px',
										}}
									>
										Delivery
									</h5>
									<div
										className={`product-miracle-select ${
											activeRadio === getActive(val)
												? 'product-miracle-select__selected'
												: ''
										}`}
										onClick={() => {
											setRotateChevron(
												(currenValue) => !currenValue
											);
										}}
										onBlur={() => {
											setRotateChevron(false);
										}}
										style={{
											pointerEvents:
												activeRadio === getActive(val)
													? 'auto'
													: 'none',
										}}
									>
										<React.Suspense fallback={<Loader />}>
											<MiracleSelect
												type='other'
												{...{
													optionsObjects,
													selectedValue,
													setSelectedValue,
													handleSelect,
													origin,
													disabled: !isValid,
												}}
											/>
										</React.Suspense>
										<img
											className={`select-chevron ${
												rotateChevron
													? 'rotate-chevron'
													: 'rotate-chevron-reverse'
											}`}
											src={chevron}
											alt='chevron for product select'
										/>
									</div>
									<div className='delivery-radios-v3__container-box__benefits'>
										<div>
											<StaticImage
												src='../../../static/images/check-rose.png'
												alt='cancel anytime'
												className='delivery-radios-v3__container-box__benefits-icon'
											/>
											<p>Cancel anytime</p>
										</div>
										<div>
											<StaticImage
												src='../../../static/images/check-rose.png'
												alt='cancel anytime'
												className='delivery-radios-v3__container-box__benefits-icon'
											/>
											<p>Manage your subscription</p>
										</div>
									</div>
								</>
							)}
						</div>
					);
				})}
			</div>
			<div className='delivery-radios-v3__stock'>
				<svg
					width='14'
					height='11'
					viewBox='0 0 14 11'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='m13.09 5.267-1.312-3.063a.44.44 0 0 0-.403-.267h-1.312v-.874a.44.44 0 0 0-.438-.438H1.313a.44.44 0 0 0-.438.438V8.5a.44.44 0 0 0 .438.438h.936a1.75 1.75 0 0 0 3.377 0h2.748a1.75 1.75 0 0 0 3.377 0h.937a.44.44 0 0 0 .437-.438V5.438a.4.4 0 0 0-.035-.171m-3.027-2.455h1.023L12.023 5h-1.96zM3.936 9.375a.875.875 0 1 1 0-1.75.875.875 0 0 1 0 1.75m4.437-1.312H5.626a1.75 1.75 0 0 0-3.377 0H1.75V1.5h7.438v5.495a1.75 1.75 0 0 0-.814 1.068m1.688 1.312a.875.875 0 1 1 0-1.75.875.875 0 0 1 0 1.75m2.188-1.312h-.499a1.75 1.75 0 0 0-1.688-1.313v-.875h2.187z'
						fill='#000'
					/>
				</svg>
				<span>In stock and available for delivery</span>
			</div>
		</div>
	);
};

export default DeliveryRadioV3;
