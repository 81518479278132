import React from 'react';

import './SubscriptionModal.scss';

import poundImage from '../../../static/images/pound.svg';
import giftImage from '../../../static/images/gift2.svg';
import calendarImage from '../../../static/images/calendar.svg';
import guaranteeImage from '../../../static/images/guarantee.svg';

const SubscriptionModal = ({ setActiveRadio, setShowSubscriptionModal }) => {
	const handleSubscribe = (purchaseType) => {
		setActiveRadio(purchaseType);
		setShowSubscriptionModal(false);
	};
	return (
		<div className='subscription-modal'>
			<button
				onClick={() => handleSubscribe('one-time-purchase')}
				className='subscription-modal__close-button'
			>
				X
			</button>
			<h3>Last Chance To Save!</h3>
			<div className='subscription-modal__content'>
				<img
					src={poundImage}
					alt='Pound'
					className='subscription-modal__content-image'
				/>

				<p>Save 15% off and get FREE delivery with every order!</p>
			</div>
			<div className='subscription-modal__content'>
				<img
					src={giftImage}
					alt='Gift'
					className='subscription-modal__content-image'
				/>
				<p>Free welcome gift and free mystery gifts along the way!</p>
			</div>
			<div className='subscription-modal__content'>
				<img
					src={calendarImage}
					alt='Calendar'
					className='subscription-modal__content-image'
				/>
				<p>Change your frequency or cancel anytime</p>
			</div>
			<div className='subscription-modal__guarantee'>
				<p>Try risk free with our 100% Money-Back Guarantee</p>
				<img src={guaranteeImage} alt='Guarantee' />
			</div>
			<button
				className='subscription-modal__button-subscribe'
				onClick={() => handleSubscribe('auto-delivery')}
			>
				SUBSCRIBE & SAVE
			</button>
			<button
				className='subscription-modal__button-one-time'
				onClick={() => handleSubscribe('one-time-purchase')}
			>
				CONTINUE WITH ONE TIME PURCHASE
			</button>
		</div>
	);
};

export default SubscriptionModal;
