import React, { useContext } from 'react';

import { StoreContext } from '../../context/store-context';
import { goToCheckout } from '../../utils/helpers';

const CheckoutButtonWithoutUpsell = ({
  isCartLimitExceeded,
  modalOpen,
  isCartBlocked,
}) => {

  const {
    cart,
    cartButtonRef,
  } = useContext(StoreContext);

  const checkoutClick = () => {
    goToCheckout(cart, cartButtonRef)
  };

  return (
    <button
      style={{
        display: 'none',
      }}
      disabled={isCartBlocked}
      type='button'
      className='cart-footer__checkout cart__submit'
      onClick={isCartLimitExceeded ? modalOpen : checkoutClick}
    >
      <span>checkout</span>
    </button>
  );
};

export default CheckoutButtonWithoutUpsell;
